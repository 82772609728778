import React from 'react';
import { FormattedMessage } from 'react-intl';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby'

import withLayout from '../layout';

const getImage = graphql`
  query {
    notFoundImage: file(relativePath: { eq: "404.png" }) {
      childImageSharp {
        fixed(width: 890, height: 500) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;


const NotFoundPage = () => {
  const { notFoundImage } = useStaticQuery(getImage)
  return (
    <>
      <section className="pb-0">
        <div className="bg-overlay gradient-bg1 opacity-9" style={{ height: '80px' }} />
      </section>
      <section className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="error-box">
                <div className="error-image">
                  <Img fixed={notFoundImage.childImageSharp.fixed} alt="404 not found" />
                </div>
                <div className="error-text text-center">
                  <FormattedMessage tagName="h2" id="error404.not_found" />
                  <FormattedMessage tagName="h3" id="error404.not_found_explanation" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const customProps = {
  localeKey: 'error404',
  hideLangs: true,
  notFoundPage: true
};

export default withLayout(customProps)(NotFoundPage);
